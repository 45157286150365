<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-24 15:09:01
 * @LastEditTime: 2021-09-26 17:06:36
 * @Description: 供应物流-车辆/工地列表
 * @Param:
 * @FilePath: \JT_Web_test\src\views\enterpriseScreen_JN\components\supplyMap\siteVehicles.vue
 -->


<template>
    <div class="siteVehicles">
        <ul class="siteVehiclesNav">
            <template v-for="(item, index) in tabs">
                <li :class="{'active': activeTab===index}" :key="`tab_${index}`" @click="handleActiveTab(index)">
                    {{ item.name }}<span v-if="index!=0">{{ item.num }}</span>
                </li>
            </template>
        </ul>
        <div class="vehicleData">
            <!-- 货源地 -->
            <ul v-if="activeTab===0" class="classification">
                <li
                    :key="`construction_${index}`"
                    v-for="(item, index) in constructionTabs"
                    :class="{'active': constructionActive===index}"
                    @click="constructionSiteTabClick(index, item)"
                >
                    <h3>{{ item.num }}</h3>
                    <p>{{ item.name }}</p>
                </li>
            </ul>
            <!-- 运输车辆 -->
            <ul v-if="activeTab===1" class="classification">
                <li
                    v-for="(item, index) in carTabs"
                    :key="`car_${index}`"
                    :class="{'active': carActive===index}"
                    class="tab-item"
                    @click="carTabsClick(index, item)"
                >
                    <h3>{{ item.num }}</h3>
                    <p>{{ item.name }}</p>
                </li>
            </ul>
            <!-- 异常监控 -->
            <div v-if="activeTab===2">
                <div>
                    <el-carousel
                        :autoplay="false"
                        arrow="never"
                        height="0.76rem"
                    >
                        <el-carousel-item
                            v-for="(item, index) in abnormalTabs"
                            :key="`carousel_${index}`"
                        >
                            <ul class="classification">
                                <li
                                    v-for="(v, vIndex) in item"
                                    :key="`abnormal_${index}_${vIndex}`"
                                    :class="{'active': abnormalActive===v.sortNum}"
                                    class="tab-item"
                                    @click="clickAbnormal(v.sortNum)"
                                >
                                    <h3>{{ v.num }}</h3>
                                    <p>{{ v.name }}</p>
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="vehicleList">
            <h4>车辆列表</h4>
            <div class="vehicleTable" v-if="activeTab!==2">
                <el-input placeholder="请输入内容" v-model="vehicleSearchVal" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="handleCarlistPrj"></el-button>
                </el-input>
                <table>
                    <thead>
                        <tr>
                            <th>车牌号</th>
                            <th>
                                原材料
                            </th>
                            <th>
                                货源地
                            </th>
                            <th>
                                供应单号
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in tableData">
                            <tr :key="`table_${index}`">
                                <td>{{ item.plate_number }}</td>
                                <td>{{ item.spec_name }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.supply_no }}</td>
                                <!--  <td @click.stop="supplyInfo(item.item.supply_no)">-->
                                <!--      {{ item.supply_no }}-->
                                <!--  </td>-->
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div v-else class="abnormal-main">
                <el-input placeholder="请输入关键词(车牌号、车号、异常内容)" v-model="abnormalSearch" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="handleAbnormalSearch"></el-button>
                </el-input>
                <div class="abnormal-list infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <ul
                        v-for="item in abnormalData"
                        :key="item.id"
                    >
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.error_content"
                            placement="left"
                            popper-class="abnormalinfo-tooltip"
                        >
                            <li>
                                <span>
                                    <span class="plate-small-num">{{ item.no }}</span>
                                    <span>{{ item.error_name }}</span>
                                </span>
                                <span class="time">
                                    <span class="dot"></span>
                                    <span>{{ item.error_datetime }}</span>
                                </span>
                            </li>
                        </el-tooltip>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import largeScreenFun from '../../JS';

export default {
    name: 'site-vehicles',
    components: {},
    props: {
        projectsAddress: {
            type: [Array, Object, String ],
        },
        carStatistics: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            time: '',
            stationId: '',
            tenantId: '',

            // 在施工地、运输车辆
            vehicleSearchVal: '',
            tableData: [],

            // 异常监控
            abnormalSearch: '',
            abnormalData: [],
            abnormalIndex: 0,

            // 顶部tab切换
            tabs: [
                { name: '货源地', num: 0 },
                { name: '运输车辆：', num: 0 },
            ],
            activeTab: 0,

            // 货源地类型
            constructionTabs: [
                { name: '运输中', num: 0, sortNum: 1, classifiedData: [] },
                { name: '未运输', num: 0, sortNum: 2, classifiedData: [] },
                { name: '未完成', num: 0, sortNum: 3, classifiedData: [] },
            ],
            constructionActive: 0,

            // 运输车辆类型
            carTabs: [
                { name: '运输中', num: 0, sortNum: 1, classifiedData: [] },
                { name: '在场站', num: 0, sortNum: 2, classifiedData: [] },
                { name: '已出厂', num: 0, sortNum: 3, classifiedData: [] },
            ],
            carActive: 0,

            // 异常监控类型
            abnormalTabs: [
                [
                    { name: '停车超时', num: 0, sortNum: 1 },
                    { name: '行驶超速', num: 0, sortNum: 2 },
                    { name: '路线偏离', num: 0, sortNum: 3 },
                    { name: '罐反转', num: 0, sortNum: 4 },
                ],
                [
                    { name: '油耗异常', num: 0, sortNum: 5 },
                    { name: '压车异常', num: 0, sortNum: 6 },
                    { name: '其他异常', num: 0, sortNum: 7 },
                ],
            ],
            abnormalActive: 1,
        };
    },
    computed: {},
    watch: {
        projectsAddress: {
            handler: function (val) {
                if (val.length > 0) {
                    this.tabs[0].num = val.length;
                }
            },
            deep: true,
        },
        carStatistics(val) {
            if (val.length > 0) {
                this.constructionTabs.forEach(item => {
                    item.num = 0;
                    item.classifiedData = [];
                });
                this.carTabs.forEach(item => {
                    item.num = 0;
                    item.classifiedData = [];
                });
                val.forEach(item => {
                    if (item.status === '1') {
                        this.carTabs[0].num++;
                        this.constructionTabs[0].num++;
                        this.constructionTabs[0].classifiedData.push(item);
                        this.carTabs[0].classifiedData.push(item);
                    } else if (item.status === '2') {
                        this.carTabs[1].num++;
                        this.constructionTabs[2].num++;
                        this.carTabs[1].classifiedData.push(item);
                        this.constructionTabs[2].classifiedData.push(item);
                    } else if (item.status === '3') {
                        this.carTabs[2].num++;
                        this.carTabs[2].classifiedData.push(item);
                    }
                });
                this.tabs[1].num = val.length;
                this.tableData = this.constructionTabs[0].classifiedData;
            }
        },
    },
    created() {
        this.time = this.$parent.time;
        this.stationId = this.$parent.stationId;
        this.tenantId = this.$parent.tenantId;
        this.searchVal = this.$parent.searchVal;
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 顶部切换tab
        handleActiveTab(index) {
            this.activeTab = index;
            this.abnormalIndex = 0;
            this.constructionActive = 0;
            this.carActive = -1;
            this.activeWorkOrder = '';
            this.vehicleSearchVal = '';
            this.tableData = [];
            if (index === 0) {
                this.constructionSiteTabClick(0, this.constructionTabs[0]);
            } else if (index === 1) {
                this.carTabsClick(0, this.carTabs[0]);
            }
        },
        // 货源地切换
        async constructionSiteTabClick(index, item) {
            this.constructionActive = index;
            this.carTabsClick(index, item);
        },
        // 运输车辆切换
        async carTabsClick(index, item) {
            this.carActive = index;
            const data = item.classifiedData;
            const returnData = {
                type: 'vehicleLoading',
                data: data,
            };
            this.handleCarlistPrj(data);
            this.$emit('mapDataLoading', returnData);
        },
        // 异常监控
        clickAbnormal(type) {
            if (this.abnormalActive !== type) {
                this.abnormalActive = type;
            } else {
                this.abnormalActive = -1;
            }
            this.abnormalData = [];
            this.handleExceptions();
        },
        // 异常记录列表
        async handleExceptions() {
            const res = await largeScreenFun.getExceptions(this.abnormalSearch, this.tenantId, this.abnormalIndex, this.abnormalActive);
            if (res) {
                this.abnormalData = [...this.abnormalData, ...res.rows];
            }
        },
        // 异常记录列表回车搜索
        handleAbnormalSearch() {
            this.tableData = [];
            this.handleExceptions();
        },
        // 异常列表-无限滚动
        load() {
            this.abnormalIndex++;
            this.handleExceptions();
        },
        // 处理在施工地下车辆列表
        async handleCarlistPrj(data) {
            this.tableData = data || [];
        },
        // 运输单详情
        supplyInfo(data) {
            this.$toast({
                title: true,
                text: '发货单详情',
                type: 'eject',
                width: '10rem',
                height: '6rem',
                t_url: 'productionManagement/logisticsMonitoring/billInfo',
                viewPosition: 'view',
                extr: { pcb_number: data },
            });
        },
    },
    filters: {
        // 派车表编号截取
        handlePcbNum(val) {
            let str = '';
            if (val) {
                str = val.substr(7, val.length);
            }
            return str;
        },
    },
};
</script>
<style lang="stylus" scoped></style>
